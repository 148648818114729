import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ThemeProvider} from '@primer/react-brand'

import {cohortFunnelBuilder} from '../../../lib/analytics'

import HeroSection from './_components/HeroSection'
import PricingSection from './_components/PricingSection'
import FeaturesSection from './_components/FeaturesSection'
import ResourcesSection from './_components/ResourcesSection'
import FootnotesSection from './_components/FootnotesSection'
import FaqSection from './_components/FaqSection'

export default function NewFeaturesCopilotIndex() {
  const {siteCopilotPurchaseRefresh} = useRoutePayload<{siteCopilotPurchaseRefresh: boolean}>()
  const {userHasOrg} = useRoutePayload<{userHasOrg: boolean}>()
  const {logged_in} = useRoutePayload<{logged_in: boolean}>()
  let {copilotSignupPath} = useRoutePayload<{copilotSignupPath: string}>()
  let {copilotForBusinessSignupPath} = useRoutePayload<{copilotForBusinessSignupPath: string}>()
  let {copilotForBusinessSignupPathRefresh} = useRoutePayload<{copilotForBusinessSignupPathRefresh: string}>()
  let {copilotEnterpriseSignupPath} = useRoutePayload<{copilotEnterpriseSignupPath: string}>()
  let {copilotContactSalesPath} = useRoutePayload<{copilotContactSalesPath: string}>()
  const {cft} = useRoutePayload<{cft: string}>()

  const withCft = cohortFunnelBuilder(cft)

  copilotSignupPath = withCft(copilotSignupPath, {product: 'cfi'})
  copilotForBusinessSignupPath = withCft(copilotForBusinessSignupPath, {product: 'cfb'})
  copilotForBusinessSignupPathRefresh = withCft(copilotForBusinessSignupPathRefresh, {product: 'cfb'})
  copilotEnterpriseSignupPath = withCft(copilotEnterpriseSignupPath, {product: 'ce'})
  copilotContactSalesPath = withCft(copilotContactSalesPath)
  const copilotPlansPath = withCft('/features/copilot/plans')

  return (
    <ThemeProvider colorMode="dark" className="lp-Copilot">
      <HeroSection copilotSignupPath={copilotSignupPath} copilotPlansPath={copilotPlansPath} isLoggedIn={logged_in} />
      <PricingSection
        siteCopilotPurchaseRefresh={siteCopilotPurchaseRefresh}
        copilotSignupPath={copilotSignupPath}
        copilotForBusinessSignupPath={copilotForBusinessSignupPath}
        copilotForBusinessSignupPathRefresh={copilotForBusinessSignupPathRefresh}
        copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
        copilotContactSalesPath={copilotContactSalesPath}
        userHasOrg={userHasOrg}
        isLoggedIn={logged_in}
      />
      <FeaturesSection />
      <ResourcesSection />
      <FaqSection />
      <FootnotesSection />
    </ThemeProvider>
  )
}

try{ NewFeaturesCopilotIndex.displayName ||= 'NewFeaturesCopilotIndex' } catch {}