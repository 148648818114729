import {Grid, SectionIntro, Text} from '@primer/react-brand'
import PricingCards from './PricingCardsNew'
import PricingTable from './PricingTableNew'
import IdeList from './IdeList'

interface PricingSectionProps {
  siteCopilotPurchaseRefresh: boolean
  copilotSignupPath: string
  copilotForBusinessSignupPath: string
  copilotForBusinessSignupPathRefresh: string
  copilotEnterpriseSignupPath: string
  copilotContactSalesPath: string
  userHasOrg: boolean
  isLoggedIn: boolean
  isExpanded?: boolean
  showLabel?: boolean
}

export default function PricingSection({
  siteCopilotPurchaseRefresh,
  copilotSignupPath,
  copilotForBusinessSignupPath,
  copilotForBusinessSignupPathRefresh,
  copilotEnterpriseSignupPath,
  copilotContactSalesPath,
  userHasOrg,
  isLoggedIn,
  isExpanded = false,
  showLabel = true,
}: PricingSectionProps) {
  return (
    <section id="pricing" className="lp-Section lp-Section--compact lp-SectionIntro--regular lp-Section--pricing mb-0">
      <Grid className="lp-Section-container--centerUntilMedium lp-Grid--noRowGap text-center position-relative">
        <Grid.Column span={12}>
          <SectionIntro align="center" fullWidth className="lp-SectionIntro">
            {showLabel && (
              <SectionIntro.Label size="large" className="lp-Label--section">
                Pricing
              </SectionIntro.Label>
            )}
            <SectionIntro.Heading size="2" weight="semibold">
              Take flight with GitHub&nbsp;Copilot
            </SectionIntro.Heading>
          </SectionIntro>
        </Grid.Column>
      </Grid>

      <PricingCards
        siteCopilotPurchaseRefresh={siteCopilotPurchaseRefresh}
        copilotSignupPath={copilotSignupPath}
        copilotForBusinessSignupPath={copilotForBusinessSignupPath}
        copilotForBusinessSignupPathRefresh={copilotForBusinessSignupPathRefresh}
        copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
        copilotContactSalesPath={copilotContactSalesPath}
        userHasOrg={userHasOrg}
        isLoggedIn={isLoggedIn}
      />

      <div className="lp-Pricing-IDE-section">
        <Grid className="lp-Section-container--centerUntilMedium lp-Grid--noRowGap text-center position-relative">
          <Grid.Column span={12}>
            <Text as="div" size="200" variant="muted" weight="medium" style={{marginBottom: 'var(--base-size-24)'}}>
              Open GitHub Copilot in your favorite platforms:
            </Text>
            <IdeList type="small" location="pricing-cards" />
          </Grid.Column>
        </Grid>
      </div>

      {isExpanded && (
        <PricingTable
          siteCopilotPurchaseRefresh={siteCopilotPurchaseRefresh}
          copilotSignupPath={copilotSignupPath}
          copilotForBusinessSignupPath={copilotForBusinessSignupPath}
          copilotForBusinessSignupPathRefresh={copilotForBusinessSignupPathRefresh}
          copilotContactSalesPath={copilotContactSalesPath}
          footnotesModifier={-1}
          isLoggedIn={isLoggedIn}
        />
      )}

      {!isExpanded && (
        <div className="lp-Section--new-pricing-experience-table">
          <details>
            <summary className="p-0">
              <h3>Compare all features</h3>
              <div style={{position: 'relative'}} className="pl-5">
                <span className="icon-collapsed pl-3" />
                <span className="icon-expanded pl-2" />
              </div>
            </summary>
            <PricingTable
              siteCopilotPurchaseRefresh={siteCopilotPurchaseRefresh}
              copilotSignupPath={copilotSignupPath}
              copilotForBusinessSignupPath={copilotForBusinessSignupPath}
              copilotForBusinessSignupPathRefresh={copilotForBusinessSignupPathRefresh}
              copilotContactSalesPath={copilotContactSalesPath}
              footnotesModifier={-1}
              isLoggedIn={isLoggedIn}
            />
          </details>
        </div>
      )}
    </section>
  )
}

try{ PricingSection.displayName ||= 'PricingSection' } catch {}