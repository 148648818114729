import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ContentfulFaqGroup} from '@github-ui/swp-core/components/contentful/ContentfulFaqGroup'
import {Grid} from '@primer/react-brand'
import resolveResponse from 'contentful-resolve-response'
import {toPayload} from '../../../../lib/types/payload'
import {isFeatureCopilotPage, toContainerPage, toEntryCollection} from '../../../../lib/types/contentful'

export default function FaqSection() {
  const {contentfulRawJsonResponse} = toPayload(useRoutePayload<unknown>())
  const page = toContainerPage(toEntryCollection(resolveResponse(contentfulRawJsonResponse)).at(0))

  return (
    <>
      {/* FAQGroup content is managed through Contentful: */}
      {isFeatureCopilotPage(page) && (
        <section id="faq" className="lp-Section lp-Section--level-1">
          <Grid>
            <Grid.Column span={12} className="lp-FAQs">
              <ContentfulFaqGroup component={page.fields.template.fields.faqGroup} />
            </Grid.Column>
          </Grid>
        </section>
      )}
    </>
  )
}

try{ FaqSection.displayName ||= 'FaqSection' } catch {}