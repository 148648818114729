import {Box, SubNav} from '@primer/react-brand'
import {SUBNAV_LINKS} from './SubNavNew.data'

interface CopilotSubNavProps {
  currentUrl?: string
}

export default function CopilotSubNav({currentUrl}: CopilotSubNavProps) {
  return (
    <>
      <Box className="lp-SubNav-spacer" />
      <SubNav className="lp-Hero-subnav lp-Hero-subnav--hide-first">
        <SubNav.Heading href={SUBNAV_LINKS.logo.url} className="d-block position-relative lp-Hero-subnav-heading">
          {SUBNAV_LINKS.logo.label}
        </SubNav.Heading>
        {SUBNAV_LINKS.items.map(item => {
          const isCurrentUrl = item.url === currentUrl
          return (
            <SubNav.Link
              key={`subnav_${item.url}`}
              href={isCurrentUrl ? '#' : item.url}
              className={isCurrentUrl ? 'selected' : ''}
              aria-current={isCurrentUrl ? 'page' : undefined}
            >
              {item.label}
            </SubNav.Link>
          )
        })}
      </SubNav>
    </>
  )
}

try{ CopilotSubNav.displayName ||= 'CopilotSubNav' } catch {}